//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeartIcon32px',
  props: {
    pathClass: {
      type: String,
      default: ''
    }
  }
};
